<template>
  <div id="app">
    <!-- ======= Header ======= -->
    <NavBar />

    <!-- End Header -->
    <IconButton />

  
    <router-view :key="route.fullPath"></router-view>
    
    <!-- ======= Footer ======= -->

    <a href="#" class="btt"><i class="arrow up"></i></a>

    <div>
      <footer id="footer">
        <div class="container footer-bottom clearfix">
          <div class="copyright">
            &copy; Copyright <strong><span>UnionOcean</span></strong
            >. All Rights Reserved
          </div>
        </div>
      </footer>
    </div>

    <!-- End Footer -->
  </div>
</template>

<style lang="scss">
@import "./assets/CSS/app.css";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 10px;

  a {
    font-weight: bold;
    color: #2c3e50;
  }
}

.arrow {
  border: solid rgb(255, 255, 255);
  border-width: 0px 3px 3px 0;
  display: inline-block;
  padding: 3px;
}

.up {
  transform: rotate(-135deg);
}

.btt{
	width:30px;
  height: 30px;
	position:fixed;
	right:10px;
	bottom:65px;
	z-index:99;
  line-height: 30px;
  border-radius: 100%;
  color: white;
  background-color: #00489b;
  
}



</style>

<script>
import IconButton from "./components/IconButton.vue";
import NavBar from "./Views/NavBar.vue";
import { useRoute } from "vue-router";

export default {
  props: {},
  data() {
    return {
      active: 0, // 当前激活的导航索引
    };
  },
  methods: {},
  components: { NavBar, IconButton },
  setup(){
    const route = useRoute();
    return {
      route
    };

  }
};
</script>
