<template>
  <div class="container">
    <div
      id="carouselExampleIndicators"
      class="carousel slide"
      data-bs-ride="true"
    >
      <div class="carousel-indicators" style="margin-bottom: -0.5rem">
        <!-- 按" Ctrl+/ "把註記取消或是使用-->
        <!-- 按鈕1 -->
        <button
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide-to="0"
          class="active"
          aria-current="true"
          aria-label="Slide 1"
        ></button>

        <!-- 按鈕2 -->
        <!-- <button
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide-to="1"
          aria-label="Slide 2"
        ></button> -->

        <!-- 按鈕3 -->
        <!-- <button
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide-to="2"
          aria-label="Slide 3"
        ></button> -->
      </div>

      <div class="carousel-inner">
        <!-- Banner 1 -->
        <!-- <div class="carousel-item active">
          <div class="row">
            <div
              class="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <h1>2024台北國際書展 即將展開!</h1>
              <h2>
                書展現場備有多款深受雙語中小學喜愛使用的教材，
                <br>
                歡迎您來看看~<strong>2/20-2/25</strong>聯洋文化在<strong>外文書區A903</strong>
                <br>期待與您見面!
              </h2>
              <div
                class="d-flex justify-content-center justify-content-lg-start"
              ></div>
            </div>
            <div
              class="col-lg-6 order-1 order-lg-2 hero-img"
              data-aos="zoom-in"
              data-aos-delay="200"
            >
              <img
                src="../assets/img-1/TIBEbanner01.png"
                class="img-fluid"
                alt="Banner 2"
              />
            </div>
          </div>
        </div> -->

        <!-- Banner 1
        <div class="carousel-item active">
          <div class="row">
            <div style="padding: 10%; text-shadow: 0px 0px 6px rgba(255,255,255,0.7);"
            >
            <h1> 因電話系統更新維護中，目前暫無法接聽來電。</h1>
              <h2> 
                若需進一步的服務，請來信聯洋文化<a style="color: midnightblue;" href="mailto:info@union.com.tw">info@union.com.tw</a>，將有專人回覆
                不便之處,敬請見諒。
              </h2>
              
              
            </div>
          </div>
        </div> -->

        <!-- Banner 2 -->
        <div class="carousel-item active">
          <div class="row">
            <div style="text-shadow: 0px 0px 3px rgba(0,0,0,0.5);"
              class="col-lg-6 d-flex flex-column justify-content-center pt-3 pt-lg-0 order-2 order-lg-1"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <h1>Welcome</h1>
              <h1>Back To School</h1>
              <h2>如需了解更多產品資訊，歡迎點擊按鈕下載產品目錄。</h2>
              <div
                class="d-flex justify-content-center justify-content-lg-start"
              >
                <a
                  href="https://drive.google.com/file/d/1zX1klWxuven5gbmOduU8c3vQh0vq-aYz/view"
                  target="_blank"
                  class="btn-get-started scrollto"
                  >Download</a
                >
                <a
                  href="https://www.youtube.com/watch?v=P4t1U_8fKXk"
                  target="_blank"
                  class="glightbox btn-watch-video"
                  ><i class="bi bi-play-circle"></i><span>Watch Video</span></a
                >
              </div>
            </div>
            <div
              class="col-lg-6 order-1 order-lg-2 hero-img"
              data-aos="zoom-in"
              data-aos-delay="200"
            >
              <img
                src="../assets/img-1/astronaut-09.png"
                class="img-fluid animated"
                alt="Banner 1"
              />
            </div>
          </div>
        </div>

        <!-- Banner 3 -->
        <!-- <div class="carousel-item active">
          <div class="row">
            <div
              class="col-lg-6 d-flex flex-column justify-content-center pt-3 pt-lg-0 order-2 order-lg-1"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <h1>春節連假暫停出貨!!</h1>
              <h2>如有疑問可來信至客服信箱<a href="mailto:info@union.com.tw">info@union.com.tw</a></h2>
              <div
                class="d-flex justify-content-center justify-content-lg-start"
              >
                <a
                  href="https://drive.google.com/file/d/1zX1klWxuven5gbmOduU8c3vQh0vq-aYz/view"
                  target="_blank"
                  class="btn-get-started scrollto"
                  >Download</a
                >
                <a
                  href="https://www.youtube.com/watch?v=P4t1U_8fKXk"
                  target="_blank"
                  class="glightbox btn-watch-video"
                  ><i class="bi bi-play-circle"></i><span>Watch Video</span></a
                >
              </div>
            </div>
            <div
              class="col-lg-6 order-1 order-lg-2 hero-img"
              data-aos="zoom-in"
              data-aos-delay="200"
            >
              <img
                src="../assets/img-1/2024_NEWYEAR.png"
                class="img-fluid animated"
                alt="Banner 1"
              />
            </div>
          </div>
        </div> -->
      </div>

      <!-- <button
        class="carousel-control-prev" style="top:50% ;width: 3%;"
        type="button"
        data-bs-target="#carouselExampleIndicators"
        data-bs-slide="prev"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button
        class="carousel-control-next" style="top:50% ;"
        type="button"
        data-bs-target="#carouselExampleIndicators"
        data-bs-slide="next"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button> -->
    </div>
  </div>
</template>
